import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Typography from '../components/Typography';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },  
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  sideimg: {
    width: '80%',
    height: '80%',
    paddingLeft: 70,
    paddingTop: 40,
    paddingBottom: 200,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  h6: {  
    marginTop: theme.spacing(2),
  },
  para: {
    fontSize: '20px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: 'justify',
    display: 'block',
  },  
});

function Directory(props) {
  const { classes } = props;

  return (
      <Container className={classes.root}>

          <Typography variant="h3" gutterBottom marked="center" align="center">
            Directory
          </Typography>

          <Typography variant="h6" className={classes.h6}>
            Watch this space
          </Typography>


          <Grid container spacing={2}>

            <Grid item xs={8}>

              <Typography variant="body1" className={classes.para}>
              {'In the near future junk2funk will list recommendations for upcycling on this page. '}
              </Typography>    


              <Typography variant="body1" className={classes.para}>
              {'This will include local Midlands-based businesses, initiatives, groups and online resources that teach the ways of upcycling.'}
              </Typography> 

              <Typography variant="body1" className={classes.para}>
              {'In the mean time, feel free to use the Contact form to tip us off with any resources that you would like to see included on this page.'}
              </Typography>     

            </Grid>          

            <Grid item xs={4}>              
              <img className={classes.sideimg} src="/categories/reuse.jpeg" alt="About upcyling furniture"/>
            </Grid>

        </Grid>

      </Container>
  );
}

export default withStyles(styles)(Directory);
