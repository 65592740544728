import withRoot from "./modules/withRoot";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProductCategories from "./modules/views/ProductCategories";
import ProductQuestions from "./modules/views/ProductQuestions";
import AppFooter from "./modules/views/AppFooter";
import ProductHero from "./modules/views/ProductHero";
import ProductValues from "./modules/views/ProductValues";
import ProductDescription from "./modules/views/ProductDescription";
import AppAppBar from "./modules/views/AppAppBar";
import AboutUs from "./modules/views/AboutUs";
import Directory from "./modules/views/Directory";
import Privacy from "./modules/views/Privacy";
import Terms from "./modules/views/Terms";
import Contact from "./Contact";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Index />
        </Route>
        <Route path="/about">
          <AboutView />
        </Route>     
        <Route path="/directory">
          <DirectoryView />
        </Route>                   
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/terms">
          <TermsView />
        </Route>
        <Route path="/privacy">
          <PrivacyView />
        </Route>
      </Switch>
    </Router>
  );
}

function Index() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <ProductDescription />      
      <ProductCategories />
      <ProductValues />      
      <ProductQuestions />
      <AppFooter />
    </React.Fragment>
  );
}

function AboutView() {
  return (
    <React.Fragment>
      <AppAppBar />
      <AboutUs />
      <AppFooter />
    </React.Fragment>
  );
}

function DirectoryView() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Directory />
      <AppFooter />
    </React.Fragment>
  );
}

function PrivacyView() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Privacy />
      <AppFooter />
    </React.Fragment>
  );
}

function TermsView() {
  return (
    <React.Fragment>
      <AppAppBar />
      <Terms />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(App);
