import withRoot from './modules/withRoot.js';
import React, { useState } from 'react';
import { Box, Button, TextField, makeStyles } from "@material-ui/core";
import Typography from './modules/components/Typography';
import AppFooter from './modules/views/AppFooter';
import AppAppBar from './modules/views/AppAppBar';
import AppForm from './modules/views/AppForm';
import * as Yup from "yup";
import { Formik } from "formik";
import CSRFToken from './modules/components/csrftoken';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  feedback: {
    marginTop: theme.spacing(2),
  },
}));


function Contact() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false)
  const [snackDisplay, setSnackDisplay] = useState("")
  const [snackType, setSnackType] = useState("") // error, warning, info or success
  const [snackOpen, setSnackOpen] = React.useState(false)

  const handleOpen = () => { setOpen(true); }
  const handleClose = () => { setOpen(false); }


  function handleErrors(response) {
      if (!response.ok) {
          throw Error(response.statusText);
      }
      return response;
  }

  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].toString().replace(/^([\s]*)|([\s]*)$/g, "");
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }

  var csrftoken = getCookie('csrftoken');

  function form_success() {
    setSnackDisplay("Message received");
    setSnackType("success");
    setSnackOpen(true);     
  }

  const handleFormSubmit = (values) => {
    fetch("apiv2/comments/", {
      method:"POST",
      headers:{
        'Accept':'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify(values)
    })
    .then(handleErrors)
    .then(response => form_success() )
    .catch(error => console.log(error) );
  }

  return (
    <React.Fragment>
      <AppAppBar />
      <AppForm>
        <React.Fragment>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Contact us
          </Typography>
          <Typography variant="body2" align="center">
            {'We will reply as soon as we can '}
          </Typography>
        </React.Fragment>

        <Formik
            initialValues={{
              name: "",              
              email: "",
              comment: "",              
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .max(120)
                .required("Name is required"),              
              email: Yup.string()
                .email("Must be a valid email")
                .max(100)
                .required("Email is required"),
              comment: Yup.string()
                .max(450)
                .required("Comment is required"),                
            })}
            onSubmit={async (values) => {
              try {
                handleFormSubmit(values);
              } catch (e) {
                alert(e.message);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleSubmit,
              handleChange,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="name"
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.comment && errors.comment)}
                  fullWidth
                  helperText={touched.comment && errors.comment}
                  label="Comments"
                  margin="normal"
                  name="comment"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.comment}
                  variant="outlined"
                  multiline
                  rows={4}
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Send comments
                  </Button>
                </Box>
                <CSRFToken />
              </form>
            )}
          </Formik>
      </AppForm> 
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
        open={snackOpen} 
        autoHideDuration={3000} 
        onClose={handleClose}>
          <Alert onClose={handleClose} 
            severity={snackType}>
          { snackDisplay }
          </Alert>
        </Snackbar> 

      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Contact);
