import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../components/Typography';
import { Link } from "react-router-dom";

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  button: {
    border: '4px solid currentColor',
    borderRadius: 0,
    height: 'auto',
    padding: theme.spacing(2, 5),
    minWidth: 200,    
  },
  link: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
});

function ProductQuestions(props) {
  const { classes } = props;
  const [color, setColor] = useState([]);  

  const loadStatus = async () => {
    const response = await fetch('apiv2/style/');
    const data = await response.json();

    if (data.length > 0) {
      switch (data[0]['theme']) {
        case "0":
          setColor('#0ba80b');
          break
        case "1":
          setColor('#ffdd00');
          break
        case "2":
          setColor('#e63333');         
          break
        default:
          setColor('#0ba80b');         
      }
    } else {
      setColor('#0ba80b');    
    }
  };
  useEffect(() => {
    loadStatus();
  }, []);

  return (
    <Container className={classes.root} component="section">
      <Link to={"/contact"}>
        <Button
          variant="contained"
          size="large"
          style={{backgroundColor:color}}
          className={classes.button}
          component="a"
        >
          Contact Us
        </Button>
      </Link>
      <Typography variant="subtitle1" className={classes.link}>
      Got any questions? We are here to help. Get in touch!
      </Typography>
    </Container>
  );
}

ProductQuestions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductQuestions);
