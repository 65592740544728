import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import ReactMarkdown from 'react-markdown';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
  para: {
    fontSize: '20px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: 'justify',
    display: 'block',
  },   
});

function ProductDescription(props) {
    const { classes } = props;
    const [description, setDescription] = useState([]);
    const loadContent = async () => {
        const response = await fetch('apiv2/content/0/');
        const data = await response.json();
        setDescription(data);
    };
  useEffect(() => {
    loadContent();
  }, []);

  return description ? (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={12}>
            <div className={classes.item}>

              <Typography variant="h4" marked="center" align="center" component="h2">
                Welcome
              </Typography>

                {description.map(item =>
                      <Typography 
                        variant="body1" 
                        marked="center" 
                        className={classes.para}
                        key={item.element}>
                          <ReactMarkdown>{item.text}</ReactMarkdown>
                      </Typography>)
                }
            </div>
          </Grid>

        </Grid>
      </Container>
    </section>

  ) : (
    <Typography>Loading</Typography>
);


}

ProductDescription.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductDescription);
