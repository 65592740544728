import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from "@material-ui/core/styles";
import Typography from '../components/Typography';
import FormatQuoteOutlinedIcon from '@material-ui/icons/FormatQuoteOutlined';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },  
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  sideimg: {
    width: '80%',
    height: '80%',
    paddingLeft: 70,
    paddingTop: 40,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  h6: {  
    marginTop: theme.spacing(2),
  },
  para: {
    fontSize: '20px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    textAlign: 'justify',
    display: 'block',
  },  
});

function AboutUs(props) {
  const { classes } = props;

  return (
      <Container className={classes.root}>

          <Typography variant="h3" gutterBottom marked="center" align="center">
            About
          </Typography>

              <Typography variant="h6" className={classes.h6}>
                What is upcycling?
              </Typography>

              <Typography variant="p" className={classes.para}>
              {'Upcycling is a simple and effective way of turning old things into new, without breaking the product down into its raw constituent materials, which is what recycling does in its simplest form. Essentially an old object is taken that is destined for the rubbish or for destruction as it has reached the end of its lifecycle or practical use and it is almost reinvented, sometimes hardly being recognisable as to what it once was. '}
              </Typography>
          
              <Typography variant="p" className={classes.para}>
              {'Sadly, many good quality things still end up at landfill as they simply cannot be recycled or form part of the renewable product system. From usable furniture, electronics, or clothing, it’s a disturbing sight to see valuable materials and resources going to waste in this manner. It requires a bit of creativity, but it can be great fun creating something new out of something old, and there is lots of inspiration and ideas online.'}
              </Typography>

              <Typography variant="p" className={classes.para}>
              {'When we start to recycle, old materials are taken, they are broken down and then turned or changed into new materials that we can use to make new products. However, while recycling is one of the best ways to reduce your environmental impact and repurpose old materials, there is a way to take your eco-footprint one step further and this has a simple and catchy name, in short this is precisely what “upcycling” is!'}
              </Typography>

              <Typography variant="p" className={classes.para}>
              {'There is no doubt that recycling is great for the environment, this is a simple and undisputed fact, however, the process can take time, and end up being a while before the item is finally given a new life. With upcycling on the other hand, it is often a much simpler solution and more time effective and even more carbon neutral if you are really looking at the eco-footprint of processes. You can even reuse or repurpose something yourself, and give it a new lease of life, which is great for items that are not so easy to recycle.'}
              </Typography>



            <Grid container spacing={2}>

              <Grid item xs={8}>

                <Typography variant="h6" className={classes.h6}>
                  Fashion
                </Typography>

                <Typography variant="p" className={classes.para}>
                {'The production process of the textile industry is complex and needs huge amounts of resources to function, not inclusive of energy, water, polluting chemicals and land space for warehousing etc. Therefore, upcycling comes as a naturally eco-friendly solution that’s starting to be developed not only by small artisans but also starting to boom (though more in a re-crafting concept).'}
                </Typography>

                <Typography variant="p" className={classes.para}>
                {'Damaged clothes no longer fit for wearing are “disassembled” and their textiles get to reused for the creation of some other piece of clothing or as cases, bookmarks or wherever the designer’s creativity takes them.'}
                </Typography>

                <Typography variant="p" className={classes.para}>
                {'There are so many fabric items that can be made out of clothing that isn’t fit to be donated to a charity shop. It doesn’t need to go to landfill! Clothing can be transformed into things like face masks, cushion covers, tote bags or shopping bags and much more! '}
                </Typography>          

                <Typography variant="p" className={classes.para}>
                {'Get creative, you can dye clothing, or add applique or embroidery details to create something that looks like new. Your imagination is your only limit!'}
                </Typography>

              </Grid>

              <Grid item xs={4}>
                <img className={classes.sideimg} src="/categories/fashion.jpeg" alt="About upcyling fashion"/>
              </Grid>


              <Grid item xs={8}>

                <Typography variant="h6" className={classes.h6}>
                  Furniture
                </Typography>

                <Typography variant="p" className={classes.para}>
                {'It’s about taking old, wasted or broken furniture and re-purposing it. From broken cabinets whose shelves can be screwed into an old door entry door that will work as a decoration piece, or putting some feet supports in a one-side opened bathtub and turning into a sofa: there are no limits to the power of creativity in upcycling furniture and other household items.'}
                </Typography>

              </Grid>

              <Grid item xs={4}>
                <img className={classes.sideimg} src="/categories/pallet_sofa.jpeg" alt="About upcyling fashion"/>
              </Grid>


            </Grid>

      </Container>
  );
}

export default withStyles(styles)(AboutUs);
